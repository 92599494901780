.info-first{
    height: 80vh;
    background:url('../images/info1.jpg') no-repeat;
    background-position: -50px -120;
    background-size: contain;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.info-second{
    height: 80vh;
    background:url('../images/croppedinfo.jpg') no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
}

.info-second-container{
    position: absolute;
    background: url('../images/infobg.svg') no-repeat;
    background-size: cover;
    height: 355px;
    top:25%;
    width: 75%;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding-left: 20%;
}

.info-second-container .title-header{
    font-weight: 800;
    font-size: 55px;
    line-height: 50px;
    margin: 0px;
}

.info-section-a{
    width: 20%;
    /* background:url('../images/secondhome.jpg') no-repeat; */
}



.info-section-b{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: url('../images/infobg1.svg') no-repeat;
    background-position: right;
    background-size: cover;
    align-items: center;
    padding-right: 20px;
}

.info-section-b h2{
    color:#ffc400;
    font-size: 55px;
}

.info-section-b p{
    color:#ffffff;
}

.infowidth{
    width: 200%;
}

.infosecwidth{
    width: 70%;
}

.info-second-container-mobile{
    display: none;
}

@media screen and (max-width: 480px) {
    .info-first .info-section-a{
        background-color: #000;
    }

   


    .info-section-b{
        padding-right: 0px;
        
    }

    .infowidth{
        width: 0px;
    }

    .info-second-container{
        background: none;
        position: relative;
        align-self: left;
    }

    .infosecwidth{
        width: 100%;
    }

    .info-second{
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        padding: 2%;
        font-weight: 600;
        background: linear-gradient(89.58deg,#0000006e  .52%,rgba(73,135,138,0) 106.66%),url('../images/croppedinfo.jpg');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;

    }

    .info-second-container-mobile{
        background-size: cover;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    .info-second-container{
        display: none;
    }

}


