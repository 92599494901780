.footer-container{
    height:auto;
    background-color: #000;
    padding:5%;
};

 h4{
    color:#fff;
}


@media screen and (max-width: 480px) {
    .footer-container{
       min-height: 90vh
    }
}
