.invest-background-container{
    background: linear-gradient(89.58deg, #ffffff 28.82%, rgba(255, 255, 255, 0) 86.66%), url('../images/invest.jpg');
    background-size: cover;
    min-height: 100vh;
    /* background-attachment: fixed; Add this line     */
  }
  
  .invest-main{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    width: 50%;
    padding-bottom: 10%;
  }

  .button{
    background-color: #E2E8F0;
    border-radius: 6px;
    width: auto;
    cursor: pointer;
    font-size: 12px;
    border: none;
    padding: 10px;
}
  
  .invest-sec{
    height: 100vh;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  
  .invest-sec-image{
    background:url('../images/dkbg.png');
    width: 45%;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    background-size: cover;
    /* margin-left: -25%; */
  }
  
  .invest-sec .title-2{
    font-size: 23px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    color:#000000;
  }
  
  .rule-container{
    width: 300px;
    display: flex;
    flex-direction: row;
    margin-top:5px;
    margin-bottom: 10px;
  }
  
  a {
    color: inherit;
  }
  
  .rule1{
    height: 3px;
    width: 30%;
    background-color: #000;
    margin: 0px;
    border: none;
    margin-right:2px;
  }
  
  .invest-main .title-2{
    font-size: 23px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    color:#000000;
  }

 
  .journey-background{
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .invest-main {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  
   
  
    
  }
  
  .invest-main .title{
    font-size: 28px;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0px;
    color:#ffffff;
  }
  
  @media (max-width: 768px) {
    .invest-main .title {
      font-size: 36px;
    }
  }
  
  .invest-now-section .title{
    font-size: 28px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    color:#ffffff;
    margin-bottom: 10px;
  
  }
  
  @media (max-width: 768px) {
    .invest-now-section .title {
      font-size: 24px;
    }
  
    
  }
  
  .invest-main .desc{
    font-size: 20px;
    font-family: 'Montserrat';
    margin: 0px;
    color:#475569
  }
  
  .desc-2{
    font-size: 16px;
    font-family: 'Montserrat';
    margin: 0px;
    color:#475569;
    margin-top:10px;
    color:#000;
    font-size:20;
  }
  
  .barCompleted {
    background-color: green;
    width: 80%;
  }
  
  .invest-now-section{
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .tsig-now-section{
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .tsig-now-section .title{
    font-size: 38px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    color:#ffffff;
  
  }
  
  .invest-target{
    background-color: #f4f6fba3;
    padding: 10px;
    border-radius: 10px;
    width: 45%;
    min-height: 90px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    height: auto;
    min-height: 100vh;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  .grid-item {
    padding: 5px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .grid-item:hover{
    background-color: #f4f6fb;
  }
  
  .custom-investment{
    min-height: 50vh;
    background-color: #ffffff;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .custom-investment .title{
    font-size: 28px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    color:#ffffff;
    margin-bottom: 10px;
  }
  
  .input-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .input-container input{
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #C0C0C0;
    background-color: transparent;
    width: 200px;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .input-container input {
      font-size: 36px;
      width: 150px;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  
  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
  
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  
  .currency-switcher{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 170px;
    height: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
  }
  
  .invest-button{
    position: fixed;
    top: 50%;
    right: 0;
    color:#fff;
    transform: translateY(-50%);
    background-color: #48878A;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 120px;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
    font-family: 'Montserrat';
  }
  
  .invest-button a{
    text-decoration: none;
  }
  
  .invest-button:hover{
    background-color: #2b5355;
    color: #fff;
    cursor: pointer;
  }
  
  .currency-item{
    cursor: pointer;
    padding: 5px;
    font-weight: 700;
  }
  
  .selected{
    background-color: #5d5d5d;
    color:#fff;
  }
  
  .currency-item:hover{
    background-color: #989898;
    color:#fff;
  }
  
  .loader {
    box-sizing: border-box;
    display: inline-block;
    width: 50px;
    height: 80px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    position: relative;
    background: linear-gradient(#FF3D00 30px, transparent 0) no-repeat;
    background-size: 2px 40px;
    background-position: 50% 0px;
    animation: spinx 5s linear infinite;
    }
    .loader:before, .loader:after {
    content: "";
    width: 40px;
    left: 50%;
    height: 35px;
    position: absolute;
    top: 0;
    transform: translatex(-50%);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0 0 20px 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0px;
    animation: lqt 5s linear infinite;
    }
    .loader:after {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    animation: lqb 5s linear infinite;
    }
    @keyframes lqt {
    0%, 100% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% 0px;
    }
    50% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% 40px;
    }
    50.1% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% -40px;
    }
    }
    @keyframes lqb {
    0% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0 40px;
    }
    100% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0 -40px;
    }
    }
    @keyframes spinx {
    0%, 49% {
      transform: rotate(0deg);
      background-position: 50% 36px;
    }
    51%, 98% {
      transform: rotate(180deg);
      background-position: 50% 4px;
    }
    100% {
      transform: rotate(360deg);
      background-position: 50% 36px;
    }
    }
  
    .capital-structure{
      min-height: 100vh;
      background-image: url("../images/topribbon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      padding-top: 100px;
    }
  
    .grid-flex{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .circular-chat{
      width: 200px;
      height: 200px;
      border-radius: 200px;
      background-color: #004aad;
      color:#fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
  
    .circular-chat-small{
      width: 150px;
      height: 150px;
      border-radius: 150px;
      background-color: #004aad;
      color:#fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      border: 2px solid #fff;
    }
  
    .circular-chat .ttle{
      font-size: 35px;
      margin: 0px;
    }
  
    .circular-chat .des{
      font-size: 15px;
      margin: 0px;
    }
  
    .circular-chat .smt{
      font-size: 10px;
      margin: 0px;
    }
  
    .plus{
      margin:5px;
      font-size: 50px;
      color:#004aad;
    }
  
    .square-chat{
      width: 150px;
      height: 150px;
      border-radius: 20px;
      background-color: #004aad;
      color:#fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .list-section{
      width: 50%;
    }
  
    .list-section li{
      color:#004aad;
    }
  
    .invest-flex{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      align-items: center;
    }

    
  
    .title-container-flex{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color:#004aad;
      text-align: center;
      align-items: center;
      margin-top: 20px;
    }
  
    .section-title{
      color:rgb(0, 0, 0);
      width: 320px;
      border:3px solid #ffc400;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      font-size: 13px;
    }
  
    .section-title-bordered{
      color:#004aad;
      width: 300px;
      border:1px solid #00d5f5;
      text-align: center;
      padding: 10px;
      font-weight: 600;
    }
  
    .centered{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 20px;
      cursor: pointer;
      border-radius: 20px;
      width: 35%;
    }
  
    .centered:hover{
      background-color: #fafafa;
    }
  
    .row-circle{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  
    .right-arrow{
        display: inline;
      }
  
      .down-arrow{
        display: none;
    }
  
    .second-circle{
      margin-top: -10px;
      margin-left: -20px; 
    }
  
    .last-circle{
      margin-top: -40px;
    }
  
    .mobile-structure{
      display: none;
    }
  
    @media (max-width: 768px) {
      .invest-flex{
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        width: 100%;
        height: auto;
      }
  
      .circular-chat{
        width: 150px;
        height: 150px;
      }
  
      .circular-chat .ttle{
        font-size: 25px;
        margin: 0px;
      }
    
      .circular-chat .des{
        font-size: 12px;
        margin: 0px;
      }
    
      .circular-chat .smt{
        font-size: 10px;
        margin: 0px;
      }
  
      .chart{
        width: 140px;
      }
  
      .right-arrow{
        display: none;
      }
  
      .down-arrow{
        display: inline;
      }
  
      .row-circle{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
  
      .second-circle{
        margin-top: 2px;
        margin-left: 0px; 
      }
  
      .last-circle{
        margin-top: 2px;
      }
  
      .capital-structure{
        min-height: 0px;
        height: auto;
      }
  
      .structure{
        display: none;
      }
  
      .mobile-structure{
        display: inline;
      }

      .journey-background{
        display: none;
      }
  
    }

    .portfolio-container{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }

    .portfolio-card{
      height: auto;
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .portfolio-title-container{
      width: 100%;
      height: 50px;
      border: 2px solid #ffc400;
      border-radius: 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    .portfolio-title-container h3{
      font-size: 15px;
      font-weight: 600;
    }

    .portfolio-card p{
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
    }

    .why-invest-container{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .why-invest-card{
      background: url('../images/whybg.png');
      background-position: center;
      background-size: cover;
      width: 30%;
      height: 500px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .why-invest-card p{
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
      font-weight: 600;
    }

    .list-container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: -150px;
    }

    .list-container li{
      font-size: 14px;
      font-weight: 500;
    }

    .list-container-inner{
      padding: 10px;
      background-color: #eee;
    }

    .nafuico-container{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

    .nafuico-inner{
      background-color: #f1f1f1;
      width: 200px;
      display: flex;
      flex-direction:column;
      align-items:center;
      justify-content: space-around;
      font-size: 12px;
      height: 130px;
      font-weight: 600;
      border-radius: 10px;
    }

    .nafuico-inner-label{
      background-color: #f1f1f1;
      width: 200px;
      display: flex;
      flex-direction:column;
      align-items:center;
      justify-content: center;
      font-size: 13px;
      height: 30px;
    }

    .nafuico-inner-label{
      font-size: 13px;
      font-weight: 600;
    }
   

    .rule{
      color:#ff9900;
      color:#000000be;
    }
   
    .yellow-container{
      height: auto;
      width: 250px;
      padding: 10px;
      background-color: #ffc400;
      border-radius: 10px;
    }

    .white-container{
      height: auto;
      width: 300px;
      padding: 10px;
      background-color: #f1f1f1;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
    }

    .grey-container{
      background-color: #909090;
      width: 100%;
      height: 80px;
      border-radius: 10px;
      text-align: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
    }

    .grey-container p {
      font-size: 13px;
      margin:0px;
    }
  
    .colored-container-flex{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 300px;
      align-items: center;
      margin-top: 0px;
    }

    .agrostrong-container-top{
      background-color: #039700;
      width: 250px;
      height: 50px;
      border-radius: 30px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    .agro-info-card{
      background-color: #7b7b7b;
      width: 250px;
      height: 150px;
      border-radius: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      margin-top: 10px;
      padding: 10px;
    }

    .agro-info-card-centered{
      background-color: #7b7b7b;
      width: 150%;
      min-width: 250px;
      min-height: 150px;
      height: auto;
      border-radius: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      margin-top: 10px;
      padding: 10px;

    }

    .agrostrong-container-middle{
      width: 150%;
      background-color: #039700;
      height: 50px;
      border-radius: 30px;
      text-align: center;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 1000;
    }
    