/* Base styles */
.newsummary {
    height: 80vh;
    background-color: #ffc400;
    padding: 5%;
}

.newsummary h3 {
    font-size: 55px;
    font-weight: 700;
    color: #000;
}

.news {
    height: 430px;
    background-color: #fff;
    width: 100%;
    margin: 10px;
    margin-bottom: 20px;
}

.news-image-1,
.news-image-2,
.news-image-3,
.news-image-4 {
    height: 230px;
    background-size: cover;
    background-position: center;
    width: 100%;
}

.news-image-1 {
    background: url('../images/detailsimg.jpg') no-repeat;
    background-size: contain;
}

.news-image-2 {
    background: url('../images/businesscloseup.jpg') no-repeat;
    background-size: contain;
}

.news-image-3 {
    background: url('../images/Flag_of_Zimbabwe.gif') no-repeat;
    background-size: contain; /* Keep specific styling for image 3 */
}

.news-image-4 {
    background: url('../images/sendhome.jpg') no-repeat;
    background-size: cover;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .newsummary {
        /* Make the height auto to accommodate the content on smaller screens */
        height: auto;
        background-color: #fff;
    }

    .newsummary h3 {
        /* Scale down the font size */
        font-size: 32px;
    }

    .news {
        /* Adjust the height or use auto if the content might vary in size */
        height: auto;
        padding-bottom: 10px;
    }

}

@media screen and (max-width: 480px) {
    .newsummary h3 {
        /* Further reduce font size for very small screens */
        font-size: 24px;
    }

    /* If you have text or other elements in .news that need adjustments,
       add those styles here. Consider the readability on small screens. */
}