.contact-main{
    height: 80vh;
    background: url('../images/contact-main.jpg') no-repeat;
    background-position: center;
    background-size: cover;
}

.contact-main-inner{
    background: url('../images/yellowrightArrow.svg') no-repeat;
    background-position: right;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-main-inner h3{
    font-size: 55px;
    margin-left: 5%;
    color: #fff;
    font-weight: 800;
}

.contact-info{
    height: 400px;
    display: flex;
    flex-direction: row;
}

.contact-info-inner{
    height: 100%;
    width: 50%;
    background-color: #000;
    padding: 5%;
}

.contact-form-page{
    padding: 5%;
    height: auto;
}

input{
    height: 50px;
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #c1c1c1;
    margin-bottom: 10px;
}

textarea, input:focus{
    outline: none;
}

textarea{
    min-height: 60%;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #c1c1c1;
    margin-bottom: 10px;
    padding: 2%;
}

.iframe-holder{
    width: 50%;
}

@media screen and (max-width: 480px) {
    
    .contact-logo{
        height: 20px;
    }

    textarea{
        padding: 5%;
    }

    .contact-info{
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .iframe-holder{
        width: 100%;
        height: 300px;
    }

}

