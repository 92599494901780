.news-main-container{
    height: 80vh;
    background: url('../images/newsbg.jpg') no-repeat;
    background-position: center;
}

.news-inner-container{
    height: 100%;
    background: url('../images/yellowrightArrow.svg') no-repeat;
    background-position: right;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.news-inner-container h3{
    font-weight: 800;
    font-size: 55px;
    color: #000000;
    margin-left: 5%;
}

.news-cards-container{
    height: auto;
    padding: 10%;
}

.news-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 400px;
    align-items: flex-start;
}

.news-short-info{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.news-content{
    padding: 5%;
}

