.nav-dk{
    height:70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline-end: 20px; /* Adjusted from 40px for better use of space */
    padding-inline-start: 30px;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
}

.logo, .logo-menu, .floating-btn, .btn-primary, .btn-secondaryx {
  /* It's a good practice to set max-width or max-height to ensure these elements are not too large on smaller screens */
  max-width: 100%;
}

.slider-one h1, .home-second h1 {
  font-size: 55px; /* Responsive font size */
  width: 80%; /* More flexible than 50% */
}

.home-title{
  font-size: 55px; /* Responsive font size */
  width: 80%; /* More flexible than 50% */
  color: #fff;
}

.logo{
    height: 50px;
}

.logo-menu{
    width: 40px;
    cursor: pointer;
}

.slider-one{
    height: 100%;
    /* background: url('../images/blackComp.svg') no-repeat; */
    background-position: -200px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    position: relative;
}

.slider-one h1{
    color:#fff;
    font-size: 55px;
    width: 50%;
}

.flex-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top:20px;
    width: 250px;
}



.btn-primary{
    background-color: none;
    border: none;
    background-size:cover;
    color:#000000;
    background: url('../images/btnprimary.svg') no-repeat;
    min-width: 200px;
    width:auto;
    height: 40px;
    margin-right: 20px;
    text-align: left;
    padding-left: 10%;
}

.btn-secondaryx{
    background-color: none;
    border: none;
    background: url('../images/btnsecondary.svg') no-repeat;
    background-size: cover;
    color:#000000;
    min-width: 200px;
    width:auto;
    height: 40px;
    margin-right: 20px;
    text-align: left;
    padding-left: 10%;
}

.floating-btn{
    position: absolute;
    right:0px;
    bottom: 10%;
    background-color: none;
    border: none;
    background: url('../images/floatingbtn.svg') no-repeat;
    background-size:cover;
    color:#000000;
    min-width: 20px;
    width: 160px;
    height: 80px;
    text-align: right;
    font-weight: 600;
}

/* ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
} */

/* li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
} */

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
  }


.home-main{
    background: url('../images/newslider.jpeg')no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 10%;
}

.home-second{
    background: linear-gradient(89.58deg,#0000006e  .32%,rgba(73,135,138,0) 106.66%),url('../images/2.\ Agrostrong\ Courasel.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 10%;
}

.home-third{
    background: linear-gradient(89.58deg,#00000072  .32%,rgba(73,135,138,0) 106.66%),url('../images/tsigirobg.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 10%;
}

.home-fourth{
    background: linear-gradient(89.58deg,#000000 .32%,rgba(73,135,138,0) 106.66%),url('../images/tsigirobg.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 10%;
}

@keyframes growBounceAnimation {
    0% { transform: scale(0); }
    80% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: #333;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  
  .menu-container.open {
    transform: translateX(0);
    animation: growBounceAnimation 0.5s forwards;
  }
  
  .menu-trigger {
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .menu-item {
    color: white;
    padding: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  @media screen and (max-width: 480px) {
    /* Further adjustments for very small screens */
    .nav-dk {
        padding-inline-start: 15px;
        padding-inline-end: 15px;
    }

    .slider-one, .home-second {
        padding-left: 5%; /* Reduce padding on very small screens */
    }

   

    .menu-container {
        width: 100%; /* Full-width slide-out menu on very small screens */
    }
}

/* Consider adding a .menu-trigger that toggles the visibility */
.menu-trigger {
    display: none; /* Initially hidden */
}

@media screen and (max-width: 768px) {
  
    .menu-trigger {
        display: block;
    }

    .slider-one{
      height: auto;
      padding: 10px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .btn-secondaryx{
      margin-top: 20px;
  }

  .floating-btn{
    display: none;
  }

  .btn-secondaryx{
    display: none;
  }
  

  .flex-buttons{
    display: table-column;
  }

  .slider-one{
    background: none;
  }

  .home-main{
    background: url('../images/newslider.jpeg')no-repeat;
    background-size: cover;
    background-position: center;
}
}
