/* Base styles (these are already provided by your code) */

.investments-summary {
    height: auto;
    background-color: #fff;
    padding: 5%;
}

.investments-summary h3 {
    font-size: 55px;
    font-weight: 700;
    color: #000;
}

.investments-list {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.investment-item {
    height: 600px;
    background-color: #414141;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

.investment-logo {
    height: 60px;
}

.investment-descr {
    color: #fff;
    height: 50%;
    margin-top: 10px;
}

.invest-main-container {
    height: 80vh;
    background: url('../images/investmentsbg.jpg') no-repeat;
    background-size: contain;
    background-position: right;
}

.invest-main-inner {
    background: url('../images/darkArrow.svg') no-repeat;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.invest-main-inner h3 {
    color: #fff;
    font-size: 55px;
    font-weight: 800;
    margin-left: 5%;
}

.investment-cards-container {
    height: auto;
    display: flex;
    padding: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.investment-card {
    width: 80%;
    height: 450px;
    margin: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    color: #fff;
    padding: 5%;
}

.invest-insure{
    background: linear-gradient(360.58deg, #000000bf 28.82%, rgba(255, 255, 255, 0) 86.66%), url('../images/5.\ 21st\ Century\ Life.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.invest-agro{
    background: linear-gradient(360.58deg, #000000bf 28.82%, rgba(255, 255, 255, 0) 86.66%), url('../images/2.\ Agrostrong\ Courasel.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.invest-tsig{
    background: linear-gradient(360.58deg, #000000bf 28.82%, rgba(255, 255, 255, 0) 86.66%), url('../images/tsigirobginvest.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

/* Responsive adjustments */

@media screen and (max-width: 768px) {
    .investments-summary h3,
    .invest-main-inner h3 {
        font-size: 30px; /* Smaller font size for smaller screens */
    }

    .investments-list {
        flex-direction: column; /* Stack list items vertically on small screens */
    }

    .investment-item {
        width: 100%; /* Use more screen width for each item on smaller screens */
        height: 600px; /* Adjust height automatically */
        margin-bottom: 20px; /* Add some margin between items when stacked */
    }

    .invest-main-container, .invest-main-inner {
        height: auto; /* Adjust container heights automatically */
        padding: 20px 0; /* Add some vertical padding */
    }
    
    .invest-main-container {
        background-size: cover; /* Change background size to cover for a better fit */
    }

    .investment-card {
        width: 90%; /* Use more of the viewport width */
    }
}

/* You might add more media queries for other breakpoints as needed, 
   tuning elements for an optimal display on various devices. */