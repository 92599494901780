.gallery-container{
    height: 80vh;
    background:url('../images/gallery.jpg') no-repeat;
    background-position: center;
    background-size: cover;
}

.inner-gallery-container{
    width: 100%;
    height: 100%;
    background: url('../images/yellowrightArrow.svg') no-repeat;
    background-position: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.inner-gallery-container h3{
    font-size: 55px;
    color: #fff;
    margin-left: 5%;
    font-weight: 800;
}

.gallary-full-container{
    height: auto;
    padding: 5%;
}

.gallery-title-container{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.gallery-title-container .selected-title {
    color:#ffc400;
    cursor: pointer;
}

.gallery-images{
    padding: 5%;
}

@media screen and (max-width: 480px) {

    .gallery-title-container{
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
    }

    .selected-title{
        font-size: 12px;
        margin-right: 10px;
    }

    .smaller-title{
        font-size: 12px;
    }
}