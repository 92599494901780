.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30vh; /* Changed from 80vh to auto to accommodate content */
  padding: 1rem 0; /* Add some padding */
}

.icon {
  height: 50px;
  margin-bottom: 10px;
}

.stat-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3px; /* Add some margin for spacing */
}

.stat-title {
  margin: 0px;
  font-size: 50px; /* This font size will be adjusted */
  color: #ffc400;
  font-weight: 800;
}

/* Adjust your animation keyframes if necessary */

@media screen and (max-width: 768px) {

  .stat-title {
      font-size: 30px; /* Decrease font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  
  .stats {
      flex-direction: column; /* Stack stats vertically */
      justify-content: center;
      align-items: center;
      height: auto;
  }

  .stat-title {
      font-size: 24px; /* Further reduce font size for very small screens */
  }
}

@keyframes swipeInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .swipe-in-left {
    animation: swipeInFromLeft 1.5s forwards;
  }


  @keyframes swipeInFromRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .swipe-in-right {
    animation: swipeInFromRight 1.5s forwards;
  }

  @keyframes growAnimation {
    from {
      transform: scale(0.2); /* Start from half the size */
      opacity: 0.5;
    }
    to {
      transform: scale(1); /* Scale to natural size */
      opacity: 1;
    }
  }
  
  .grow {
    animation: growAnimation 1.5s forwards;
  }

  @keyframes bounceAnimation {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .bounce {
    animation: bounceAnimation 2s ease;
  }