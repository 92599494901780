.about-us-container{
    height: 80vh;
    background: url('../images/aboutusmain.jpg') no-repeat;
    background-size: contain;
    background-position: right;
    width: 100%;
    padding-top: 30px;
}

.about-us-main{
    background: url('../images/bnylogo.svg') no-repeat;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5%;
}

.about-us-main h3{
    color:#fff;
    font-size: 55px;
    font-weight: 700;
}

.details-container{
    height: 80vh;
}

.invest-now-container{
    min-height: 100vh;
    height: auto;
    background-color: #ffc107;
}

.details-img{
    height: 500px;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
    width: 300px;
    background: url('../images/detailsimg.jpg') no-repeat; 
    background-size: cover;
    background-position: center;
}

.invest-img{
    height: 780px;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
    width: 450px;
    background: url('../images/vhusiinvest.png') no-repeat; 
    background-size: cover;
    background-position: center;
    margin-right: 10%;

}

@media screen and (max-width: 768px) {
    .about-us-container{
        height:'auto';
        background-position: center;
        background-size: cover;
    }

    .about-us-main{
        height: 100%;
        background: none;
    }

    .details-container{
        height: auto;
    }

    .details-img{
        display: none;
    }
}