.team-container{
    height: 80vh;
    background: url('../images/team.jpg') no-repeat;
    background-size: cover;
    padding-top: 70px;
}

.team-inner-container{
    background: url('../images/blackd.svg') no-repeat;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.team-inner-container h3{
    color:#ffffff;
    font-size: 85px;
    margin-left: 1%;
    font-weight: 800;
}

.teams-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 10px;
}

.team-card {
    height: 300px;
    width: 300px;
    background-color: #ffc400;
    margin: 20px;
}

.team-descr{
    padding: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.team-img{
    height: 100%;
    width: 50%;   
}

.lv{
    background:url('../images/Lovemore\ 2.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.beki{
    background:url('../images/websiteImages/team/beki.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.munya{
    background:url('../images/websiteImages/team/minya.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.nocawe{
    background:url('../images/websiteImages/team/nocawe.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.gina{
    background:url('../images/websiteImages/team/gina.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.algna{
    background:url('../images/websiteImages/team/algna.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.vhusi{
    background:url('../images/Vhusi.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.bonono{ 
    background:url('../images/websiteImages/team/bonono.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.gift{
    background:url('../images/Gift.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.elliot{
    background:url('../images/Elliot.png') no-repeat;
    background-size: cover;
    background-position:center;
}

.alphonce{
    background:url('../images/Alphonce\ Bhunu\ \ .jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.fundi{
    background:url('../images/fundi.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.cathy{
    background:url('../images/cathy.JPG') no-repeat;
    background-size: cover;
    background-position: center;
}

.owen{
    background:url('../images/Owen\ Potani.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.winston{
    background:url('../images/Winstone\ Masango\ \ .jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

/* Base CSS (Your Provided Styles) */
.team-container {
    height: 80vh;
    background: url('../images/team.jpg') no-repeat;
    background-size: cover;
    padding-top: 70px;
}

.team-inner-container {
    background: url('../images/blackd.svg') no-repeat;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.team-inner-container h3 {
    color: #ffffff;
    font-size: 85px;
    margin-left: 1%;
    font-weight: 800;
}

.teams-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 10px;
}

.team-card {
    height: 300px;
    width: 300px;
    background-color: #ffc400;
    margin: 20px;
}

.team-descr {
    padding: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.team-img {
    height: 100%;
    width: 50%;
}

/* Specific Backgrounds */
.lv, .vhusi, .gift, .elliot, .alphonce, .owen, .winston {
    background-size: cover;
    background-position: center;
}

/* Responsive Adjustments */
@media (max-width: 1024px) { /* For Tablets */
    .team-inner-container h3 {
        font-size: 65px; /* Smaller font for smaller devices */
    }

    .teams-container, .team-inner-container {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        height: 50%;
        justify-content: center;
        align-items: flex-start;
        
    }

    .team-card {
        width: 80%; /* Make the card wider on smaller screens for better visibility */
        max-width: 300px; /* Ensure it doesn't grow uncontrollably */
        margin: 10px auto; /* Center cards with automatic horizontal margins */
    }
}

@media (max-width: 768px) { /* For Mobile Devices */
    .team-inner-container h3 {
        font-size: 40px; /* Even smaller font for mobile devices */
    }

    .team-descr {
        flex-direction: column; /* Stack description elements vertically */
    }
}



